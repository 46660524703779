



















































































































































import { ElForm } from 'element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';
import { getUserData } from '@/serve/permissionsManage/userManage.ts';
import AddAndEditForm from './addAndEditForm.vue';
import { deleteUser } from '@/serve/permissionsManage/userManage.ts';
@Component({
  name: 'UserManage',
  components: { AddAndEditForm },
  filters: {
    typeLockFlag(num: number): string {
      const type = {
        0: '有效',
        1: '无效',
      };
      return (type as any)[num];
    },
  },
})
export default class UserManage extends Vue {
  public userData = [];
  public page = {
    current: 1,
    size: 20,
  };
  public form = {
    phone: '',
    checkerName:''
  };
  public total = 0;
  public handleType = '新增';
  public dialogFormVisible = false;
  public created() {
    this.getUserData(this.form);
  }
  //获取用户表数据,如果有searchVal说明是点击搜索
  public async getUserData(searchVal: any): Promise<void> {
    const data = this.page;
    searchVal && Object.assign(data, { phone: searchVal.phone,checkerName: searchVal.checkerName });
    const res = await getUserData(data);
    searchVal && Object.assign(data, { phone: "" });
    this.userData = res.data.records;
    this.total = res.data.total;
  }
  //点击第N页触发获取数据
  public handleCurrentChange(val: any): void {
    this.page.current = val;
    this.getUserData(this.form);
  }
  //点击每页显示的条数触发获取数据
  public handleSizeChange(val: any): void {
    this.page.size = val;
    this.getUserData(this.form);
  }
  //触发新增按钮
  public addUser() {
    (this.$refs.addUserForm as AddAndEditForm).addUser();
  }
  //触发修改按钮
  public editRow(index: number, row: any) {
    const data = JSON.stringify(row);
    (this.$refs.addUserForm as AddAndEditForm).editUser(
      index,
      JSON.parse(data)
    );
  }
  public deleteUser(row: { userId: string; username: string }) {
    (this as any).$messageBox
      .confirm(` 是否继续删除该用户${row.username}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
      .then(async () => {
        const res = await deleteUser(row.userId);
        this.getUserData(this.form);
        res.data &&
          this.$message({
            message: '删除成功',
            type: 'success',
          });
      });
  }
  /*重置功能*/
  public resetForm(formName: string){
    (this.$refs[formName] as ElForm).resetFields();
    this.form.phone='';
    this.form.checkerName='';
    console.log(this.form)
    /*再次请求 */
    this.getUserData(this.form);
    console.log("重置")
  }
}
